import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';

const BlogListGeneric = ({ postType, heading }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchPosts();
  }, [postType]);

  const fetchPosts = async () => {
    try {
      const allPosts = await axios.get(`${process.env.REACT_APP_API_URL}/api/upload_post/`, {
        withCredentials: true, // This ensures cookies are sent
      });
  
      const filteredPosts = allPosts.data.filter((p) => p.post_type === postType);
  
      setPosts(filteredPosts);
      console.log('Fetched Posts:', filteredPosts);
    } catch (error) {
      console.error(`Error fetching ${postType} posts:`, error);
      enqueueSnackbar(`Failed to load ${heading} posts. Please try again.`, {
        variant: 'error',
      });
    }
  };

  const isImage = (fileUrl = '') => /\.(jpe?g|png|gif)$/i.test(fileUrl);
  const isPdf = (fileUrl = '') => /\.pdf$/i.test(fileUrl);
  const isPpt = (fileUrl = '') => /\.(pptx?|ppsx?)$/i.test(fileUrl);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        variant="h3"
        sx={{ mt: 4, mb: 4, color: 'primary.main', textAlign: 'center' }}
      >
        {heading}
      </Typography>

      {posts.map((post) => (
        <Card key={post.id} sx={{ mb: 3, boxShadow: 3 }}>
          <CardContent>
            <Typography
              variant="h5"
              sx={{ color: 'primary.main', fontWeight: 'bold' }}
            >
              {post.title}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mb: 1 }}>
              {post.date}
            </Typography>
            <Typography variant="body1" sx={{ color: 'text.primary' }}>
              {post.description?.slice(0, 300)}...
            </Typography>
          </CardContent>

          {/* Collapsible Section for File Preview */}
            {post.file && (
            <Box sx={{ p: 2 }}>
                {/* Download Button (Visible for All File Types) */}
                <Button
                variant="outlined"
                onClick={() => window.open(post.file_url, '_blank', 'noopener,noreferrer')}
                sx={{ mr: 1, mb: 2 }}  // Adds spacing below the button
                >
                Download File
                </Button>

                {/* Collapsible Preview Section */}
                <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>View</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {/* Image Preview */}
                    {isImage(post.file) && (
                    <Box
                        component="img"
                        src={post.file_url}
                        alt="Attached"
                        sx={{
                        maxWidth: '100%',
                        height: 'auto',
                        display: 'block',
                        margin: '0 auto',
                        }}
                    />
                    )}

                    {/* PDF Preview */}
                    {isPdf(post.file) && (
                    <Box sx={{ position: 'relative', width: '100%', height: '500px', mb: 2 }}>
                        <iframe
                        src={post.file_url}
                        title="PDF Preview"
                        style={{ width: '100%', height: '100%', border: 'none' }}
                        />
                    </Box>
                    )}

                    {/* PowerPoint Download Option (This is specific to PPT, but general download above covers it too) */}
                    {isPpt(post.file) && (
                    <Button
                        variant="outlined"
                        onClick={() => window.open(post.file_url, '_blank', 'noopener,noreferrer')}
                        sx={{ mr: 1 }}
                    >
                        Download PowerPoint
                    </Button>
                    )}

                    {/* Other File Types */}
                    {!isImage(post.file) && !isPdf(post.file) && !isPpt(post.file) && (
                    <Button
                        variant="outlined"
                        onClick={() => window.open(post.file_url, '_blank', 'noopener,noreferrer')}
                    >
                        View/Download File
                    </Button>
                    )}
                </AccordionDetails>
                </Accordion>
            </Box>
            )}
        </Card>
      ))}
    </Box>
  );
};

export default BlogListGeneric;