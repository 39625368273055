import React from 'react';
import { Box, Typography } from '@mui/material';
import SmartShipper from './SmartShipperIndex';

const SmartShipperBlacklist = () => {
  return (
    <Box component="main" sx={{ mt: 4 }}>
        <Box
            sx={{
            backgroundColor: '#f9f9f9',
            padding: 3,
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            marginTop: '50px',
            marginBottom: 4,
            textAlign: 'center',
            transition: 'transform 0.3s ease, boxShadow 0.3s ease',
            '&:hover': {
                transform: 'translateY(-8px)',
                boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
            },
            }}
        >
        <Box
        sx={{
            display: 'flex',
            gap: 2,
            justifyContent: 'center', 
            flexWrap: 'wrap', 
        }}
        >
            <SmartShipper />    
        </Box>
      </Box>
    </Box>
  );
};

export default SmartShipperBlacklist;