import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { addSerialNumber } from './SmartShipperActions';

const SmartShipperForm = ({ onSerialAdded }) => {
  const [serialNumber, setSerialNumber] = useState('');
  const [job, setJob] = useState('');
  const [notes, setNotes] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!serialNumber || !job) {
      alert("Serial Number and Job are required!");
      return;
    }

    try {
      await addSerialNumber({ serial_number: serialNumber, job, notes });
      setSerialNumber('');
      setJob('');
      setNotes('');
      onSerialAdded();  // Refresh table
    } catch (error) {
      alert("Failed to add serial number.");
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mb: 2, display: 'flex', gap: 2 }}>
      <TextField
        label="Serial Number"
        variant="outlined"
        value={serialNumber}
        onChange={(e) => setSerialNumber(e.target.value)}
        required
      />
      <TextField
        label="Job"
        variant="outlined"
        value={job}
        onChange={(e) => setJob(e.target.value)}
        required
      />
      <TextField
        label="Notes"
        variant="outlined"
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <Button variant="contained" color="primary" type="submit">
        Add Serial
      </Button>
    </Box>
  );
};

export default SmartShipperForm;