import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableBody, TableRow, TableCell, Button, CircularProgress, Typography, Box } from '@mui/material';
import { fetchSerialNumbers, deleteSerialNumber } from './SmartShipperActions';

const SmartShipperTable = ({ refresh }) => {
  const [serialNumbers, setSerialNumbers] = useState([]); // Ensure state is always an array
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSerialNumbers = async () => {
      setLoading(true);
      try {
        const data = await fetchSerialNumbers();
        console.log("API Response:", data); // Debugging
        if (Array.isArray(data)) {
          setSerialNumbers(data);
        } else {
          console.error("Unexpected API response:", data);
          setSerialNumbers([]); // Ensure empty array
        }
      } catch (error) {
        console.error("Error fetching serial numbers:", error);
        setSerialNumbers([]); // Set empty array on failure
      }
      setLoading(false);
    };
    loadSerialNumbers();
  }, [refresh]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this serial number?")) {
      try {
        await deleteSerialNumber(id);
        setSerialNumbers(serialNumbers.filter(sn => sn.id !== id)); // Remove from UI
      } catch (error) {
        alert("Failed to delete serial number.");
      }
    }
  };

  return (
    <Box>
      {loading ? <CircularProgress /> : (
        serialNumbers.length > 0 ? (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Serial Number</TableCell>
                <TableCell>Job</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serialNumbers.map((sn) => (
                <TableRow key={sn.id}>
                  <TableCell>{sn.serial_number}</TableCell>
                  <TableCell>{sn.job}</TableCell>
                  <TableCell>{sn.notes}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="error" onClick={() => handleDelete(sn.id)}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography variant="h6" align="center" sx={{ mt: 2, color: 'gray' }}>
            No serial numbers found. Add a new one!
          </Typography>
        )
      )}
    </Box>
  );
};

export default SmartShipperTable;