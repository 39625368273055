// src/components/intranet/Sections.js
import React from 'react';
import { Box } from '@mui/material';
import Links from './Links';
import KevinsCard from '../intranet/Cards/KevinsCard';
import NewsCard from '../intranet/Cards/NewsCard';
import WelcomeMessage from '../intranet/Welcome/WelcomeMessage';

const Sections = () => (
  
  <Box component="main" sx={{ mt: 4 }}>
        <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between',  
        gap: { xs: 1, md: 3 },
        flexDirection: { xs: 'column', md: 'row' },
        alignItems: 'stretch',   // Ensure items stretch to the same height
        mb: 1,
        mt: 5,
      }}
    >
      {/* Kevin's Corner as a Card Link */}
      <Box 
        sx={{ 
          flex: { xs: 'unset', md: 1 },
          display: 'flex',              // Make inner Box a flex container
          flexDirection: 'column',      // Stack content vertically
        }}
      >
        <KevinsCard />
      </Box>

      <Box 
        sx={{ 
          flex: { xs: 'unset', md: 2 },
          display: 'flex', 
          flexDirection: 'column',
        }}
      >  
        <WelcomeMessage />
      </Box>
      
      <Box 
        sx={{ 
          flex: { xs: 'unset', md: 1 },
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NewsCard />
      </Box>
    </Box>

    {/* Other components */}
    <Box sx={{ mb: 1 }}>
      <Links />
    </Box>
  </Box>
);

export default Sections;