import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const logos = [
  { name: 'Logo - BW (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - BW.eps' },
  { name: 'Logo - BW (PDF)', file: '/Internal/Logos/Axillon Aerospace Logo - BW.pdf' },
  { name: 'Logo - BW (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - BW.png' },
  { name: 'Logo - Color (RGB EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - Color - RGB.eps' },
  { name: 'Logo - Color (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - Color.eps' },
  { name: 'Logo - Color (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Color.png' },
  { name: 'Logo - Standard v2 (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Standard v2.png' },
  { name: 'Logo - Standard (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Standard.png' },
  { name: 'Logo - Tagline BW (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - BW.eps' },
  { name: 'Logo - Tagline BW (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - BW.png' },
  { name: 'Logo - Tagline Color (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - Color.eps' },
  { name: 'Logo - Tagline Color (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - Color.png' },
  { name: 'Logo - Tagline White (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - White.eps' },
  { name: 'Logo - Tagline White (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - Tagline - White.png' },
  { name: 'Logo - White (EPS)', file: '/Internal/Logos/Axillon Aerospace Logo - White.eps' },
  { name: 'Logo - White (PNG)', file: '/Internal/Logos/Axillon Aerospace Logo - White.png' },
];

const templates = [
  { name: 'Axillon PP Template v1', file: '/Internal/PowerPoint_Templates/Axillon Aerospace PP Template v1.pptx' },
  { name: 'Axillon PP Template v2', file: '/Internal/PowerPoint_Templates/Axillon Aerospace PP Template v2.potx' },
  { name: 'Axillon PP Template v3', file: '/Internal/PowerPoint_Templates/Axillon Aerospace PP Template v3.potx' },
];

const documents = [
  { name: 'Letterhead - Baltimore', file: '/Internal/Doc_Templates/Axillon Aerospace Letterhead - Baltimore.docx' },
  { name: 'Letterhead - Erlanger', file: '/Internal/Doc_Templates/Axillon Aerospace Letterhead - Erlanger.docx' },
  { name: 'Letterhead - Saltillo', file: '/Internal/Doc_Templates/Axillon Aerospace Letterhead - Mexico.docx' },
  { name: 'Letterhead - Rockmart', file: '/Internal/Doc_Templates/Axillon Aerospace Letterhead - Rockmart.docx' },
  { name: 'Letterhead - San Diego', file: '/Internal/Doc_Templates/Axillon Aerospace Letterhead - San Diego.docx' },
];

const emailTemplates = [
  { name: 'Email Signature Setup', file: '/Internal/Email_Templates/Axillon_Aerospace_Email_Signature_SetUp.potx' }
];

const pantoneColors = [
    { name: 'Blue', color: '#1f3e76', pantone: '654' },
    { name: 'Green', color: '#7fb539', pantone: '368' },
  ];
  
const letterTypes = [
    { name: 'Primary Font', font: 'Arial', style: 'Regular' },
    { name: 'Secondary Font', font: 'Times New Roman', style: 'Italic' }
  ];


const web_documents = [
    { name: 'Blue Carbon Weave', file: '/Carbon.png' },
    { name: 'White Carbon Weave', file: '/white.jpg' },
    { name: 'Logo512', file: '/logo512.png' },
    { name: 'favicon', file: '/favicon.ico' },
    { name: 'Background', file: '/Axillon-Aerospace-Background.png' },
  ];

const MarketingMaterials = () => {
    return (
      <Box sx={{ padding: 4, mt: 6 }}>
        <Typography variant="h3" sx={{ textAlign: 'center', mb: 4, color: 'primary.main' }}>
          Marketing Materials
        </Typography>
  
        {/* Logos Section */}
        <Typography variant="h5" sx={{ mb: 2 }}>Logos</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {logos.map((logo, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              {logo.file.endsWith('.png') && (
                <img 
                  src={logo.file} 
                  alt={logo.name} 
                  style={{ maxWidth: '100%', height: '80px', objectFit: 'contain' }} 
                />
              )}
              <CardContent>
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  href={logo.file}
                  download
                >
                  Download {logo.name}
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
  
        {/* Pantone Colors Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Pantone Colors</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {pantoneColors.map((color, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2, backgroundColor: color.color, color: '#fff' }}>
              <CardContent>
                <Typography variant="h6">{color.name}</Typography>
                <Typography variant="body2">Pantone: {color.pantone}</Typography>
                <Typography variant="body2">HEX: {color.color}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
  
        {/* Letter Type Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Letter Type</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {letterTypes.map((letter, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              <CardContent>
                <Typography variant="h6">{letter.name}</Typography>
                <Typography variant="body2">Font: {letter.font}</Typography>
                <Typography variant="body2">Style: {letter.style}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
  
        {/* PowerPoint Templates Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>PowerPoint Templates</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {templates.map((template, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              <CardContent>
                <Typography variant="h6">{template.name}</Typography>
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  href={template.file}
                  download
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
  
        {/* Document Templates Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Document Templates</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {documents.map((doc, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              <CardContent>
                <Typography variant="h6">{doc.name}</Typography>
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  href={doc.file}
                  download
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>
  
        {/* Email Signature Setup Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Email Signature Setup</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {emailTemplates.map((email, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              <CardContent>
                <Typography variant="h6">{email.name}</Typography>
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  href={email.file}
                  download
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

        {/*Web app Section */}
        <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>Intranet / Webapp elements</Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: 3 }}>
          {web_documents.map((element, index) => (
            <Card key={index} sx={{ width: '250px', textAlign: 'center', p: 2 }}>
              <img 
                  src={element.file} 
                  alt={element.name} 
                  style={{ maxWidth: '100%', height: '80px', objectFit: 'contain' }} 
                />
              <CardContent>
                <Typography variant="h6">{element.name}</Typography>
                <Button
                  variant="outlined"
                  startIcon={<CloudDownloadIcon />}
                  href={element.file}
                  download
                >
                  Download
                </Button>
              </CardContent>
            </Card>
          ))}
        </Box>

      </Box>
    );
  };
  
  export default MarketingMaterials;