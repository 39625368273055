import axios from 'axios';
import { API_ENDPOINTS } from '../../../constants/apiEndpoints';

// 1) Fetch all serial numbers (GET to /serial_numbers/)
export const fetchSerialNumbers = async () => {
  try {
    console.log("Fetching from endpoint:", API_ENDPOINTS.SMARTSHIPPER_SERIAL_NUMBERS);
    const response = await axios.get(API_ENDPOINTS.SMARTSHIPPER_SERIAL_NUMBERS, { withCredentials: true });
    console.log("API Response (Serial Numbers):", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching serial numbers:", error);
    return [];
  }
};

// 2) Add a new serial number (POST to /serial_numbers/)
export const addSerialNumber = async (serialData) => {
  try {
    console.log("Posting to endpoint:", API_ENDPOINTS.SMARTSHIPPER_SERIAL_NUMBERS);
    console.log("Payload:", serialData);
    const response = await axios.post(API_ENDPOINTS.SMARTSHIPPER_SERIAL_NUMBERS, serialData, {
      withCredentials: true
    });
    console.log("API Response (Add Serial Number):", response.data);
    return response.data;
  } catch (error) {
    console.error("Error adding serial number:", error);
    throw error;
  }
};

// 3) Delete a serial number (DELETE to /serial_numbers/<id>/)
export const deleteSerialNumber = async (id) => {
  try {
    const deleteEndpoint = API_ENDPOINTS.SMARTSHIPPER_SERIAL_NUMBER_DETAIL(id);
    console.log("Deleting from endpoint:", deleteEndpoint);
    await axios.delete(deleteEndpoint, { withCredentials: true });
    console.log(`Serial number with ID ${id} deleted successfully.`);
  } catch (error) {
    console.error("Error deleting serial number:", error);
    throw error;
  }
};