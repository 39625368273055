import React, { useContext } from 'react';
import { Box, Card, CardContent, Typography, Link } from '@mui/material';
import TSATickets from './charts/IT/TSATickets';
import ITTicketsPage from './charts/IT/ITTicketsPage';
import NonTSATcikets from './charts/IT/NonTSATickets';
import { AuthContext } from '../../context/AuthContext';

const ITDashboard = () => {
  const { authData } = useContext(AuthContext);

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 3, textAlign: 'center' }}>
        IT Dashboard
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        {/* 📌 IT Tickets Chart - Always visible */}
        <Card
          sx={{
            flex: {
              xs: '1 1 100%',
              sm: '1 1 100%',
              md: '1 1 600px'
            },
            minHeight: 500
          }}
        >
          <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <Link
                href="https://axillon.atlassian.net/jira/servicedesk/projects/SUP/boards/1"
                target="_blank"
                rel="noopener noreferrer"
                underline="hover"
                sx={{ fontWeight: 'bold', color: 'inherit' }}
              >
                IT Tickets by week
              </Link>
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <ITTicketsPage />
            </Box>
          </CardContent>
        </Card>

        {/* Render Non-TSA and TSA Action Items only if the user is staff */}
        {authData && authData.is_staff && (
          <>
            <Card
              sx={{
                flex: {
                  xs: '1 1 100%',
                  sm: '1 1 100%',
                  md: '1 1 600px'
                },
                minHeight: 500
              }}
            >
              <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <Link
                    href="https://axillon.atlassian.net/jira/core/projects/ITP/board"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    sx={{ fontWeight: 'bold', color: 'inherit' }}
                  >
                    Non-TSA Action Items by status
                  </Link>
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                  <NonTSATcikets />
                </Box>
              </CardContent>
            </Card>

            <Card
              sx={{
                flex: {
                  xs: '1 1 100%',
                  sm: '1 1 100%',
                  md: '1 1 600px'
                },
                minHeight: 500
              }}
            >
              <CardContent sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  <Link
                    href="https://axillon.atlassian.net/jira/core/projects/ITP/board"
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="hover"
                    sx={{ fontWeight: 'bold', color: 'inherit' }}
                  >
                    TSA Action Items by status
                  </Link>
                </Typography>
                <Box sx={{ flexGrow: 1 }}>
                  <TSATickets />
                </Box>
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ITDashboard;