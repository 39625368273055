import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, TextField, Button, Typography, Link } from '@mui/material';
import { useAuth } from '../../../context/AuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, loading } = useAuth(); // ✅ Use AuthContext

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    const result = await login({ email, password });

    if (result.success) {
      navigate('/'); // ✅ Redirect on success
    } else {
      setError(result.error || 'Invalid email or password'); // ✅ Show error
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 2,
      }}
    >
      <Typography variant="h4" mb={2}>
        Login
      </Typography>
      {error && (
        <Typography color="error" mb={2}>
          {error}
        </Typography>
      )}
      <form onSubmit={handleLogin} style={{ width: '100%', maxWidth: '400px' }}>
        <TextField
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <Button variant="contained" type="submit" fullWidth disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </Button>
      </form>
      <Box mt={2}>
        <Link href="/password-reset" variant="body2">
          Forgot your password?
        </Link>
      </Box>
      <Box mt={1}>
        <Link href="/register" variant="body2">
          Don't have an account? Register here.
        </Link>
      </Box>
    </Box>
  );
};

export default Login;