import React, { useState } from 'react';
import { Box, Typography, Paper } from '@mui/material';
import SmartShipperTable from './SmartShipperTable';
import SmartShipperForm from './SmartShipperForm';

const SmartShipper = () => {
  const [refresh, setRefresh] = useState(false);

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" sx={{ mb: 3 }}>Serial Numbers Onhold</Typography>

      <Paper sx={{ padding: 3, mb: 3 }}>
        <Typography variant="h6">Add New Serial Number</Typography>
        <SmartShipperForm onSerialAdded={() => setRefresh(!refresh)} />
      </Paper>

      <Paper sx={{ padding: 3 }}>
        <Typography variant="h6">Serial Numbers List</Typography>
        <SmartShipperTable refresh={refresh} />
      </Paper>
    </Box>
  );
};

export default SmartShipper;