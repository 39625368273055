import { DOMAINS } from './domains'; 

export const API_ENDPOINTS = {
    TICKETS_BY_PROJECT_KEY: '/api/tickets/by-project-key/',
    TICKETS_BY_ASSIGNEE: '/api/tickets/by-assignee/',
    TICKETS_BY_STATUS: '/api/tickets/by-week-status/',
    TICKETS_TSA:'/api/tickets/by-cat-tsa/',
    TICKETS_NON_TSA:'/api/tickets/by-cat-non-tsa/',

    // SerialNumber endpoints
    // 1) List all or create new (GET or POST) => /serial_numbers/
    SMARTSHIPPER_SERIAL_NUMBERS: `${DOMAINS.backend}smartshipper/serial_numbers/`,

    // 2) Retrieve detail or delete existing => /serial_numbers/<id>/
    SMARTSHIPPER_SERIAL_NUMBER_DETAIL: (id) => 
      `${DOMAINS.backend}smartshipper/serial_numbers/${id}/`,

    // 3) Logs endpoint => /serial_number_logs/
    SMARTSHIPPER_LOGS: `${DOMAINS.backend}smartshipper/serial_number_logs/`,
  };